import React from "react"
import { Col, Row, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import ButtonGradient from "components/button/Gradient"
import { Link } from "gatsby"

const TopSectionBG = () => {
  return (
    <>
      <div className="container-background-top-area">
        <Col span={24} xl={24} lg={24} md={0} sm={0} xs={0}>
          <StaticImage
            className="top-background"
            src="../../../resources/img/bg-banner.png"
            placeholder="none"
            alt=""
          />
        </Col>
        <Col span={24} xl={0} lg={0} md={24} sm={24} xs={24}>
          <StaticImage
            className="top-background"
            src="../../../resources/img/bg-banner-mobile.png"
            placeholder="none"
            alt=""
          />
        </Col>
      </div>

      <div className="top-area">
        <Col span={20}>
          <div className="img-loading">
            <StaticImage
              alt="Outlet app"
              placeholder="none"
              className="img-loading-static"
              src="../../../resources/img/loader.svg"
            />
          </div>
          <div className="top-area-content">
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <div className="container-text-mobile-center">
                <Typography.Text className="text-big-banner">
                  Belanja bareng lebih seru lebih hemat!
                  <div className="img-sunrise">
                    <StaticImage
                      alt="Outlet app"
                      placeholder="none"
                      className="img-sunrise-static"
                      src="../../../resources/img/sunriseL.png"
                      srcSet="../../../resources/img/sunriseM.png 768w, ../../../resources/img/sunriseL.png 1280w"
                      sizes="(max-width: 500px) 500px, (max-width: 768px) 768px"
                    />
                  </div>
                </Typography.Text>
              </div>
              <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="container-text-normal-banner">
                  <Typography.Text className="text-normal-banner">
                    Pengalaman baru belanja yang lebih hemat dan menyenangkan
                    buat kamu dan teman-teman.
                  </Typography.Text>
                </div>
              </Col>
              <Col md={24} sm={24} xs={24}>
                <Row className="button-top">
                  <ButtonGradient
                    style={{}}
                    variant="white-linear"
                    title="Download"
                    href="#download"
                  />
                </Row>
              </Col>
            </Col>
            <div className="img-area">
              <StaticImage
                alt="Outlet app"
                placeholder="none"
                className="img-background-mockup"
                src="../../../resources/img/mokup.png"
              />
              <StaticImage
                alt="Outlet app"
                placeholder="none"
                className="img-background-mockup-HP"
                src="../../../resources/img/TTD Field.png"
              />
            </div>
          </div>
        </Col>
        <div className="img-arrow-down" style={{ cursor: "pointer" }}>
          <Link to="/#about">
            <StaticImage
              alt="Outlet app"
              placeholder="none"
              className="img-background-arrow"
              src="../../../resources/img/shape/arrowDown.png"
            />
          </Link>
        </div>
      </div>
    </>
  )
}

export default TopSectionBG
