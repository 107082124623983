import React from "react"
import { Col, Typography } from "antd"

import { StaticImage } from "gatsby-plugin-image"
import Aos from "aos"
import "aos/dist/aos.css"
import { useRef, useEffect } from "react"
import { ArrowRightOutlined } from "@ant-design/icons"

const GabungSection = () => {
  const myRef = useRef()
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <div id="partners" className="joinpartner" data-aos="fade-up" ref={myRef}>
      <div className="base-container-new" style={{ marginTop: 100 }}>
        <Col span={4} className="wisseller-logo-responsive">
          <div className="container-new-text-area">
            <div className="container-wismart-hero">
              <StaticImage
                className="img-wisseller"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/shape/wisseller2.png"}
              />
            </div>
          </div>
        </Col>
        <Col span={16} style={{ float: "left" }}>
          <div className="container-wismart-text">
            <Typography.Text className="subtitle-new">
              WisSeller
            </Typography.Text>
            <Typography.Text className="title-new">
              Punya produk yang mau dijual?
            </Typography.Text>
            <Typography.Text className="sub-subtitle-new">
              Yuk gabung jadi seller Wisbeli. Cukup download aplikasi Wispay
              Seller dan mulai berjualan!
            </Typography.Text>
            <div className="explore-more">
              <ul>
                <li>
                  <a
                    className="animated-arrow"
                    href="/penjual"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="the-arrow -left">
                      <span className="shaft"></span>
                    </span>
                    <span className="main">
                      <a
                        href="/penjual"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography.Text className="pelajari-new">
                          Pelajari lebih lanjut{" "}
                        </Typography.Text>
                      </a>
                      <span className="the-arrow -right">
                        <span className="shaft"></span>
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <Typography.Text className="pelajari-new Responsive">
              <a href="/penjual" target="_blank" rel="noreferrer">
                Pelajari lebih lanjut{" "}
                <ArrowRightOutlined style={{ marginLeft: 10 }} />
              </a>
            </Typography.Text>
          </div>
        </Col>
        <Col span={4} className="wisseller-logo">
          <div className="container-new-text-area">
            <div className="container-wismart-hero">
              <StaticImage
                className="img-wisseller"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/shape/wisseller.png"}
              />
            </div>
          </div>
        </Col>
      </div>
    </div>
  )
}

export default GabungSection
