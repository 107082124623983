import React from "react"
import { Card, Col, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import Meta from "antd/lib/card/Meta"
import { Carousel } from "antd"
import TestimoniSection from "./testimoni.sc"
import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect, useState, useRef } from "react"

const NewSection = () => {
  const [isAutoplay, setisAutoplay] = useState(false)
  const myRef = useRef()
  const sliderRef = useRef()

  useEffect(() => {
    Aos.init({ duration: 1000 })
    setisAutoplay(false)
  }, [])
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]

      if (entry.isIntersecting) {
        setisAutoplay(true)
      } else {
        setisAutoplay(false)
        sliderRef?.current?.goTo(0)
      }
    })
    observer.observe(myRef.current)
  }, [])
  return (
    <>
      <div
        id="about"
        className="index-container"
        data-aos="fade-up"
        ref={myRef}
      >
        <div className="base-container-new">
          <Col span={12} className="keuntungan">
            <div className="container-wismart-text">
              <Typography.Text className="subtitle-new">
                Keuntungan
              </Typography.Text>
              <Typography.Text className="title-new">
                Tiga alasan kenapa kamu harus banget pake Wisbeli
              </Typography.Text>
            </div>
          </Col>

          <Col span={8} className="recomendation-personal">
            <Carousel
              autoplay={isAutoplay}
              ref={ref => {
                sliderRef.current = ref
              }}
            >
              <div>
                <h3>
                  <Col span={24} className="Col-Card-choise">
                    <div className="container-new-text-area">
                      <div className="container-wismart-hero">
                        <Card
                          bordered={false}
                          hoverable={false}
                          cover={
                            <StaticImage
                              className="slide-bg-mobile"
                              placeholder="none"
                              alt="example"
                              src={"../../../resources/img/Mask-group.png"}
                            />
                          }
                        >
                          <Meta
                            title="Rekomendasi personal"
                            description="Dapatkan pilihan produk sesuai selera kamu"
                          />
                        </Card>
                      </div>
                    </div>
                  </Col>
                </h3>
              </div>
              <div>
                <h3>
                  <Col span={24} className="Col-Card-choise">
                    <div className="container-new-text-area">
                      <div className="container-wismart-hero">
                        <Card
                          bordered={false}
                          hoverable={false}
                          cover={
                            <StaticImage
                              className="slide-bg-mobile"
                              placeholder="none"
                              alt="example"
                              src={"../../../resources/img/Mask-group2.png"}
                            />
                          }
                        >
                          <Meta
                            title="Diskon tiap hari"
                            description="Semua produk pasti selalu ada promo tiap hari"
                          />
                        </Card>
                      </div>
                    </div>
                  </Col>
                </h3>
              </div>
              <div>
                <h3>
                  <Col span={24} className="Col-Card-choise">
                    <div className="container-new-text-area">
                      <div className="container-wismart-hero">
                        <Card
                          bordered={false}
                          hoverable={false}
                          cover={
                            <StaticImage
                              className="slide-bg-mobile"
                              placeholder="none"
                              alt="example"
                              src={"../../../resources/img/Mask-group3.png"}
                            />
                          }
                        >
                          <Meta
                            title="Cara belanja yang seru"
                            description=" Makin hemat dan seru bareng teman-teman"
                          />
                        </Card>
                      </div>
                    </div>
                  </Col>
                </h3>
              </div>
            </Carousel>
          </Col>
        </div>
        <TestimoniSection />
      </div>
    </>
  )
}

export default NewSection
