import * as React from "react"
import "resources/style/style.less"
import TopSectionBG from "components/section/content/top.sc"
import NewSection from "components/section/content/new.sc"
import KeunggulanSection from "components/section/content/keunggulan.sc"
import GabungSection from "components/section/content/gabung.sc"
import DownloadSection from "components/section/content/download.sc"
import Seo from "../components/seo"
import Layout from "components/layout"

import imageUrl from "resources/img/wispaylogo.svg"
import "@fontsource/inter"

const IndexPage = () => {
  return (
    <>
      <Seo
        title={"Wispay home"}
        description={"Belanja Barang Murah Bareng Teman - Teman Dekat Kamu"}
        imageUrl={imageUrl}
      />
      <Layout headerVariant="normal" headerBackground="blue">
        <TopSectionBG />
        <NewSection />
        <KeunggulanSection />
        <GabungSection />
        <DownloadSection />
      </Layout>
    </>
  )
}

export default IndexPage
