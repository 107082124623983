import React, { useEffect, useState, useRef } from "react"
import { Col, Typography, Spin } from "antd"
import ProdukCard from "components/items/produk-card"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import Aos from "aos"
import "aos/dist/aos.css"
import { Autoplay } from "swiper"
import testimoni from "resources/data/testimoni"

const TestimoniSection = () => {
  const myRef = useRef()
  const swipperRef = useRef()
  useEffect(() => {
    Aos.init({ duration: 1000 })
    swipperRef?.current?.swiper?.autoplay.stop()
  }, [])
  const tab = ""
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [tab])
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        swipperRef?.current?.swiper?.autoplay?.start()
      } else {
        swipperRef?.current?.swiper?.slideTo(0)
        swipperRef?.current?.swiper?.autoplay?.stop()
      }
    })
    observer.observe(myRef.current)
  }, [])

  return (
    <div id="produk" data-aos="fade-up" ref={myRef}>
      <div className="base-container-tertimoni">
        {/* <img className="shape-left2" src={ShapeLeft} alt="shape left" /> */}
        <Col span={24}>
          <div className="container-produk">
            <Typography.Text className="text-title-34 ">
              Apa kata mereka?
            </Typography.Text>
            <Typography.Text className="text-title-35 ">
              Wisbeli memang paling ngertiin pembeli
            </Typography.Text>
            <Typography.Text className="text-title-36 ">
              Wisbeli selalu ingin melayani kamu. Sekarang, kamu bisa merasakan
              kenyamanan berbelanja dengan keuntungan harga murah pastinya.
            </Typography.Text>
          </div>
          <div className="carousel-card">
            <Col xl={24} lg={0} md={0} sm={0} xs={0}>
              <Spin spinning={isLoading}>
                {" "}
                <Swiper
                  ref={swipperRef}
                  slidesPerView={"auto"}
                  autoplay={{ delay: 3000 }}
                  spaceBetween={50}
                  loop={true}
                  modules={[Autoplay]}
                  className="swiper-step"
                >
                  {testimoni.map((pro, index) => (
                    <SwiperSlide key={index}>
                      <ProdukCard
                        image={pro.icon}
                        alamat={pro.alamat}
                        nama={pro.nama}
                        desc={pro.desc}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Spin>
            </Col>
          </div>
        </Col>
      </div>
    </div>
  )
}

export default TestimoniSection
