import React, { useRef } from "react"
import { Col, Collapse, Typography } from "antd"

import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/effect-fade"
import { EffectFade, Autoplay, Pagination } from "swiper"
import thumbs from "resources/img/shape/thumbs-up.svg"
import thumbs2 from "resources/img/shape/thumbs-up2.svg"
import thumbs3 from "resources/img/shape/thumbs-up3.svg"
import { CaretRightOutlined } from "@ant-design/icons"
import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect } from "react"

const { Panel } = Collapse
const thumbsIMG = index =>
  index === 1 ? (
    <span>
      <img src={thumbs} className="thumbs" alt="Thumbs" /> STEP 1
    </span>
  ) : index === 2 ? (
    <span>
      <img src={thumbs2} className="thumbs" alt="discount" /> STEP 2
    </span>
  ) : (
    <span>
      <img src={thumbs3} className="thumbs" alt="tab" /> STEP 3
    </span>
  )

const KeunggulanSection = () => {
  const myRef = useRef()
  const swipperRef = useRef()
  useEffect(() => {
    Aos.init({ duration: 1000 })
    swipperRef.current?.swiper.autoplay.stop()
  }, [])
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        swipperRef?.current?.swiper?.autoplay?.start()
      } else {
        swipperRef?.current?.swiper?.slideTo(0)
        swipperRef?.current?.swiper?.autoplay?.stop()
      }
    })
    observer.observe(myRef.current)
  }, [])

  const menu = [
    "<div><img src=" +
      thumbs +
      " alt='logo produk'/><span>Step 1</span></div><div>Lihat rekomendasi khusus buat kamu dan pilih produknya </div></div>",
    "<div><img src=" +
      thumbs2 +
      " alt='logo produk'/><span>Step 2</span></div><div> Pilih harga 'Beli Bareng' supaya kamu dapat harga paling murah </div></div>",
    "<div><img src=" +
      thumbs3 +
      " alt='logo produk'/><span>Step 3</span></div><div> Selesaikan transaksi kamu dengan memilih pembayaran yang kamu inginkan </div></div>",
  ]
  return (
    <div id="benefits" className="benefits" data-aos="fade-up" ref={myRef}>
      <div className="base-container-new">
        <Col span={13} className="col-benefits">
          <div className="container-wismart-text">
            <Typography.Text className="subtitle-new">
              Belanja mudah dan murah
            </Typography.Text>
            <Typography.Text className="title-new">
              Tiga langkah mudah supaya bisa lebih hemat dengan Wisbeli.
            </Typography.Text>
          </div>
          <div className="site-card-wrapper">
            <div className="WP step-regis">
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </Col>
        <Col span={7}>
          <Swiper
            ref={swipperRef}
            spaceBetween={30}
            autoplay={{ delay: 3000 }}
            effect={"fade"}
            modules={[EffectFade, Autoplay, Pagination]}
            // loop={true}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              renderBullet: function (index, className) {
                return `<div class="ant-card ant-card-bordered WP  ${className}"><div class="ant-card-body">${menu[index]}</div></div>`
              },
            }}
          >
            <SwiperSlide>
              <Col span={24}>
                <div className="container-new-text-area">
                  <div className="container-wismart-hero">
                    <StaticImage
                      className="img-phone"
                      placeholder="none"
                      alt="example"
                      src={"../../../resources/img/phone-banner.png"}
                    />
                  </div>
                </div>
              </Col>
            </SwiperSlide>
            <SwiperSlide>
              <Col span={24}>
                <div className="container-new-text-area">
                  <div className="container-wismart-hero">
                    <StaticImage
                      className="img-phone"
                      placeholder="none"
                      alt="example"
                      src={"../../../resources/img/phone-banner2.png"}
                    />
                  </div>
                </div>
              </Col>
            </SwiperSlide>
            <SwiperSlide>
              <Col span={24}>
                <div className="container-new-text-area">
                  <div className="container-wismart-hero">
                    <StaticImage
                      className="img-phone"
                      placeholder="none"
                      alt="example"
                      src={"../../../resources/img/phone-banner3.png"}
                    />
                  </div>
                </div>
              </Col>
            </SwiperSlide>
          </Swiper>
          <Collapse
            accordion
            bordered={false}
            defaultActiveKey={["1"]}
            className="panel-step"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 270 : 90} />
            )}
          >
            <Panel header={thumbsIMG(1)} key="1">
              <p className="step-paragraph">
                Lihat rekomendasi khusus buat kamu dan pilih produknya.
              </p>
              <StaticImage
                className="img-phone"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/phone-banner.png"}
              />
            </Panel>
            <Panel header={thumbsIMG(2)} key="2">
              <p className="step-paragraph">
                Pilih harga "Beli Bareng" supaya kamu dapat harga paling murah.
              </p>
              <StaticImage
                className="img-phone"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/phone-banner2.png"}
              />
            </Panel>
            <Panel header={thumbsIMG(3)} key="3">
              <p className="step-paragraph">
                Selesaikan transaksi kamu dengan memilih pembayaran yang kamu
                inginkan.
              </p>
              <StaticImage
                className="img-phone"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/phone-banner3.png"}
              />
            </Panel>
          </Collapse>
        </Col>
      </div>
    </div>
  )
}

export default KeunggulanSection
